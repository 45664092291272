<template>
  <backoffice-container>
    <b-card>
      <div class="d-flex justify-content-between flex-wrap my-1">
        <h4>{{ $t('backoffice.custom-fields.manage-fields') }}</h4>
        <b-button v-b-modal.new-classifier variant="primary" class="ml-1">
          {{ $t('backoffice.custom-fields.new-field') }}
        </b-button>
      </div>
      <div v-if="types.length > 0">
        <b-table
          class="position-relative"
          :items="types"
          responsive
          :small="isSmall"
          :fields="fields"
          primary-key="id"
          show-empty
          empty-text="loading"
        >
          <!-- Column: Name -->
          <template #cell(name)="{ item }">
            <b-media class="d-flex align-items-center">
              <b-link :to="getClassifierLocation(item)" class="text-dark d-flex">
                <div class="ml-1">
                  <p class="font-weight-bold mb-0 text-dark">
                    {{ translateTranslationTable($store.state.locale.currentLocale, item.name) }}
                  </p>
                </div>
              </b-link>
            </b-media>
          </template>

          <!-- Column: Description -->
          <template #cell(description)="{ item }">
            <b-link :to="getClassifierLocation(item)" class="text-dark d-flex">
              <div class="text-nowrap">
                <p
                  v-if="
                    item.description &&
                      Object.entries(item.description).length !== 0 &&
                      translateTranslationTable($store.state.locale.currentLocale, item.description) !== ''
                  "
                  class="mb-0 ml-1 pl-25"
                >
                  {{ translateTranslationTable($store.state.locale.currentLocale, item.description) }}
                </p>
                <span v-else class="ml-1 pl-25 text-muted">---</span>
              </div>
            </b-link>
          </template>
          <!-- Column: Model -->
          <template #cell(model)="{ item }">
            <b-link :to="getClassifierLocation(item)" class="text-dark d-flex">
              <div class="text-nowrap">
                <span v-if="Array.isArray(item.modelType)">
                  <p v-for="(typeModle, i) in item.modelType" :key="i" class="mb-0 ml-1 pl-25 ">
                    {{ getModelType(typeModle) }}
                  </p>
                </span>
                <p v-else class="mb-0 ml-1 pl-25 text-capitalize">
                  {{ getModelType(item.modelType) }}
                </p>
              </div>
            </b-link>
          </template>

          <!-- Column: Type -->
          <template #cell(type)="{ item }">
            <b-link :to="getClassifierLocation(item)" class="text-dark d-flex">
              <div class="text-nowrap ml-1 pl-50 " style="max-width: 190px">
                <p class="mb-0">
                  {{ translatedTypeFields(item.type) }}
                </p>
              </div>
            </b-link>
          </template>
        </b-table>
        <div class="d-flex justify-content-center">
          <b-pagination
            v-model="page"
            :total-rows="total"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </div>
      <div v-else class="horizontal-placeholder d-block">
        <div>
          <img :src="placeholder" class="d-block">
        </div>
        <div>
          <p class="text-primary text-center">
            {{ $t('available.message', { itemName: $t('backoffice.custom-fields.name') }) }}
          </p>
        </div>
      </div>
      
      <!-- New type modal -->
      <b-modal
        id="new-classifier"
        :title="$t('backoffice.custom-fields.new-field')"
        :ok-title="$t('form.actions.save')"
        ok-only
        :ok-disabled="isDisabled"
        no-close-on-backdrop
        @hidden="resetForm()"
        @shown="addModelType()"
        @ok="saveCustomfieldType()"
      >
        <template #modal-header>
          <language-selector-header :title="isEditing ? $t('backoffice.types.edit-type') : $t('backoffice.types.create-type')" @closeModal="closeModal" @selectLanguage="(language)=>selectedLanguage = language" />
        </template>
        <!-- Name -->
        <b-form-group class="flex-grow-1 mb-2">
          <label for="rol-name" class="font-weight-bold">
             {{ $t('backoffice.roles.name') }}*
            </label>
          <b-form-input
            v-model="currentClassifier.name[selectedLanguage]"
            class="mt-50"
            :value-placeholder="$t('layouts.widget-name')"
            label-for="name"
            :state="isValid"
            @input="validator()"
          />
          <small v-if="isValid === false" class="text-danger mt-25">
            {{ $t('backoffice.classifiers.validator-message') }}
          </small>
        </b-form-group>
        <!-- Description -->
        <b-form-group label-for="description">
          <label class="font-weight-bold">{{ $t('backoffice.roles.description') }}</label>
          <b-form-textarea
            v-model="currentClassifier.description[selectedLanguage]"
            class="mt-50"
            :value-placeholder="$t('layouts.widget-name')"
            :is-description="true"
            label-attribute="description"
          />
        </b-form-group>
        <!-- Apps -->
        <b-form-group>
          <label for="rol-name" class="font-weight-bold">
             Apps*
          </label>
          <v-select
            v-model="currentClassifier.modelType"
            class="select-control p-0"
            :placeholder="$t('form.select-multiple-placeholder')"
            clearable
            :multiple="true"
            :label="locale"
            :searchable="false"
            :get-option-label="(a) => a.addon[locale] || Object.values(a.addon)[0]"
            :options="modelOptions"
          />
        </b-form-group>
        <b-form-group>
          <label for="rol-name" class="font-weight-bold"> {{ $tc('backoffice.custom-fields.type') }}*</label>
          <v-select
            v-model="currentClassifier.fieldType"
            class="select-control p-0"
            :placeholder="$t('form.select-multiple-placeholder')"
            :clearable="false"
            :get-option-label="(a) => a.name"
            :options="typeFields"
          />
        </b-form-group>
      </b-modal>
    </b-card>
  </backoffice-container>
</template>

<script>
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import BackofficeContainer from '@/backoffice/components/BackofficeContainer.vue';
import vSelect from 'vue-select';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import Placeholder from '@/assets/images/placeholders/light/general-placeholder.svg';
import LanguageSelectorHeader from '@core/components/modal/LanguageSelectorHeader.vue';

export default {
  name: 'CustomFields',
  components: {
    BackofficeContainer,
    vSelect,
    LanguageSelectorHeader,
  },
  mixins: [ToastNotificationsMixin],
  data() {
    return {
      currentClassifier: {
        name: {},
        description: {},
        modelType: [],
        backgroundColor: '#5dbfc9',
        fontColor: '#ffffff',
        multiple: false,
        fieldType: '',
        share: false,
      },
      isValid: null,
      isSmall: false,
      page: 1,
      perPage: 15,
      selectedLanguage: this.currentLocale,
      isEditing: false,
    };
  },
  computed: {
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    placeholder() {
      return Placeholder;
    },
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    isDisabled() {
      return (
        this.currentClassifier.name.length === 0
        || this.currentClassifier.modelType.length === 0
        || this.currentClassifier.modelType === ''
        || this.currentClassifier.fieldType === ''
      );
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    types() {
      return this.$store.getters.customFieldsBO.unpaginated;
    },
    total() {
      return this.$store.getters.customFieldsBO?.meta?.total;
    },
    modelOptions() {
      const modelTypesObj = this.$store.getters.modelTypes;
      const notApply = [
        'chat-individual',
        'chat-group',
        'chat',
        'map',
        'calendar',
        'meeting',
        'auditorium',
        'workspace',
        'community',
        'analytic',
        'payment',
        'classifier',
        'custom-field',
        'live-streaming'
      ];
      let newObj = [];
      //use only correct apps
      modelTypesObj.forEach((app) => {
        if(!notApply.includes(app.addonSingular)){
          newObj.push(app);
        }
      });
      return newObj;
    },
    typeFields() {
      return [
        { name: this.$t('backoffice.custom-fields.field-type.text') },
        { name: this.$t('backoffice.custom-fields.field-type.number') },
        { name: this.$t('backoffice.custom-fields.field-type.date') },
        { name: this.$t('backoffice.custom-fields.field-type.boolean') },
      ];
    },
    typeField() {
      return {
        text: this.$t('backoffice.custom-fields.field-type.text'),
        number: this.$t('backoffice.custom-fields.field-type.number'),
        date: this.$t('backoffice.custom-fields.field-type.date'),
        boolean: this.$t('backoffice.custom-fields.field-type.boolean'),
      };
    },
    fields() {
      return [
        {
          key: 'name',
          label: this.$t('backoffice.custom-fields.field-name'),
        },
        {
          key: 'description',
          label: this.$t('backoffice.roles.description'),
        },
        {
          key: 'model',
          label: this.$tc('backoffice.classifiers.model', 1),
        },
        {
          key: 'type',
          label: this.$t('backoffice.custom-fields.type'),
        },
      ];
    },
  },
  watch: {
    page(page) {
      this.fetchClassifiersType(page);
    },
    types(newValue) {
      return newValue;
    },
  },
  async created() {
    await this.fetchClassifiersType(this.page);
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    await this.getModelTypes();
    // this.currentClassifier.fieldType = Object.values(this.typeFields)[1];
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    translate(field) {
      return translateTranslationTable(this.selectedLanguage, field);
    },
    translatedTypeFields(type) {
      return this.typeField[type];
    },
    async fetchClassifiersType(page) {
      this.page = page;
      await this.$store.dispatch('getItems', {
        itemType: 'customfield',
        customName: 'customFieldsBO',
        forceAPICall: true,
        requestConfig: {
          page,
          perPage: this.perPage,
        },
      });
    },
    async saveCustomfieldType() {
      try {
        console.log('this.currentClassifier', this.currentClassifier);
        if (this.currentClassifier.name === '' || this.currentClassifier.name === {} || this.isValid === false) {
          this.notifyError(this.$t('backoffice.custom-fields.messages.create-error'));
        } else {
          const classifier = {
            name: this.currentClassifier.name,
            description: this.currentClassifier.description,
            modelType: Array.isArray(this.currentClassifier.modelType)
              ? this.currentClassifier.modelType.map((item) => item.addonSingular)
              : '',
            type: Object.keys(this.typeField).find(
              (key) => this.typeField[key] === this.currentClassifier.fieldType.name,
            ),
          };
          await this.$store.dispatch('createItem', {
            item: {
              itemType: '/customfield',
              customName: 'customFieldsBO',
              requestConfig: {
                ...classifier,
              },
            },
          });
          this.notifySuccess(this.$t('backoffice.custom-fields.messages.create-success'));
          await this.fetchClassifiersType(this.page);
        }
      } catch {
        this.notifyError(this.$t('backoffice.custom-fields.messages.create-error'));
      }
    },
    handleResize() {
      this.isSmall = window.innerWidth < 400;
    },
    validator() {
      this.isValid = this.types.every((type) => type.name[this.locale] !== this.currentClassifier.name[this.locale]);
    },
    getClassifierLocation(classifier) {
      return {
        name: 'custom-fields-details',
        params: { id: classifier.key, communityId: this.$route.params.communityId, page: this.page },
      };
    },
    resetForm() {
      this.currentClassifier = {
        name: {},
        description: {},
        modelType: '',
        backgroundColor: '#5dbfc9',
        fontColor: '#ffffff',
        multiple: false,
        fieldType: Object.values(this.typeFields)[1],
      };
      this.isValid = null;
    },
    translateTranslationTable,
    async getModelTypes() {
      await this.$store.dispatch('getModelTypes');
    },
    getModelType(type) {
      let model = '';
      if (Array.isArray(type)) {
        model = this.$store.getters.modelTypes.filter((item) => type.includes(item?.addon.en));
        model = model.map((item) => item?.addon[this.locale]);
      } else {
        model = this.$store.getters.modelTypes.filter((item) => item?.addonSingular === type)[0]?.addon[
          this.locale
        ];
      }
      return model;
    },
    addModelType() {
      this.currentClassifier.modelType = [];
      this.currentClassifier.fieldType = Object.values(this.typeFields)[1];
    },
    closeModal() {
      this.$bvModal.hide('new-classifier');
    },
  },
};
</script>
<style lang="scss" scoped>
.model-type p:first-letter {
  text-transform: uppercase;
}
</style>
